import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import {  updateField, submitForm  } from '../../features/survey/EntrepreneurSurveySlice';

import { collection, addDoc } from 'firebase/firestore';
import { db } from "../../config/firebase";
import { Button, Dropdown, Modal, Row, Col, Card, Tab, Nav } from "react-bootstrap";



function EntrepreneurSurvey() {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.EntrepreneurSurveyReducer.formData);
  const submitted = useSelector((state) => state.EntrepreneurSurveyReducer.submitted);
  const myUserId = useSelector((state) => state.auth.user.uid);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ name, value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // formData'ya myUserId'yi ekleyelim
    const formDataWithUser = {
      ...formData,
      userId: myUserId,
    };

    try {
      // Firestore'a eklerken formDataWithUser'ı kullanıyoruz
      await addDoc(collection(db, 'surveysentrepreneur'), formDataWithUser);
      dispatch(submitForm());
      alert('Survey submitted successfully!');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (

    <Fragment>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="App">
                <h1>Siber Güç Türkiye Girişimci Anketi</h1>
                {submitted ? (
                  <h2>Anket başarıyla gönderildi!</h2>
                ) : (
                <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="form-group mb-3 col-md-12">
                      <label>Girişimci Adı Soyadı: </label>
                      <input name="userName" value={formData.userName} onChange={handleChange} required className="form-control input-default " placeholder="Girişimci Adı Soyadı" />
                      <p className="text-muted fs-12">Lütfen adınızı soyadınızı adını belirtin</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Girişim Adı: </label>
                      <input name="companyName" value={formData.companyName} onChange={handleChange} required className="form-control input-default " placeholder="Girişim Adı" />
                      <p className="text-muted fs-12">Lütfen girişiminizin adını belirtin</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Kuruluş Yılı: </label>
                      <input name="foundationYear" value={formData.foundationYear} onChange={handleChange} required className="form-control input-default " placeholder="Kuruluş Yılı"  />
                      <p className="text-muted fs-12">Lütfen girişiminizin kuruluş yılını belirtin</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Girişimin dikeyi: </label>
                      <select name="vertical" value={formData.vertical} onChange={handleChange} required  className="form-control input-default ">
                        <option value="0">Seçiniz</option>
                        <option value="1">Siber Güvenlik</option>
                        <option value="2">Yapay Zeka</option>
                        <option value="3">Her İkisi</option>
                      </select>
                    </div>

                    <div className="form-group mb-3 col-md-12">
                      <label>Sunduğunuz Çözümler: </label>
                      <textarea name="solutions" value={formData.solutions} onChange={handleChange} required className="form-control input-default " placeholder="Sunduğunuz Çözümler"  />
                      <p className="text-muted fs-12">Kısaca girişiminizin sunduğu çözümleri ve faaliyet gösterdiği alanı açıklayınız</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Ekip Sayısı: </label>
                      <input name="teamSize" value={formData.teamSize} onChange={handleChange} required className="form-control input-default " placeholder="Ekip Sayısı"  />
                      <p className="text-muted fs-12">Girişiminizde çalışan toplam ekip sayısını belirtin. (Tam zamanlı, yarı zamanlı ve stajyer dahil)</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Müşteri Profili: </label>
                      <textarea name="customerProfile" value={formData.customerProfile} onChange={handleChange} required className="form-control input-default " placeholder="Müşteri Profili"  />
                      <p className="text-muted fs-12">Mevcut müşterilerinizin profili hakkında bilgi verin. (B2B, B2C, kamu, özel sektör vb.)</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Ciro Hacmi: </label>
                      <input name="turnover" value={formData.turnover} onChange={handleChange}  className="form-control input-default " placeholder="Ciro Hacmi"  />
                      <p className="text-muted fs-12">Şu ana kadar elde ettiğiniz toplam ciro hacmini belirtiniz. (Opsiyonel)</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Gelecek Planları: </label>
                      <textarea name="futurePlans" value={formData.futurePlans} onChange={handleChange} required className="form-control input-default " placeholder="Gelecek Planları"  />
                      <p className="text-muted fs-12">Girişiminizin önümüzdeki 1-3 yıl içerisindeki hedefleri ve büyüme planları nelerdir?</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>İhracat Durumu: </label>
                      <textarea name="exportStatus" value={formData.exportStatus} onChange={handleChange}required className="form-control input-default " placeholder="İhracat Durumu"  />
                      <p className="text-muted fs-12">Girişiminiz şu ana kadar ihracat yaptı mı? Eğer yaptıysa, hangi ülkelere operasyon yaptınız?</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Yerlilik Oranı: </label>
                      <input name="localizationRate" value={formData.localizationRate} onChange={handleChange} required className="form-control input-default " placeholder="Yerlilik Oranı"  />
                      <p className="text-muted fs-12">Girişiminizin sunduğu ürün ve hizmetlerdeki yerlilik oranı nedir? (Yüzde olarak)</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Ar-Ge ve Yenilikçilik Faaliyetleri: </label>
                      <textarea name="researchDevelopment" value={formData.researchDevelopment} onChange={handleChange} required className="form-control input-default " placeholder="Ar-Ge ve Yenilikçilik Faaliyetleri"  />
                      <p className="text-muted fs-12">Girişiminizin Ar-Ge çalışmaları veya inovasyon süreçleri hakkında bilgi verin. (Varsa TÜBİTAK, KOSGEB destekleri)</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Daha Önce Katıldığınız Yarışma ve Girişimcilik Programları: </label>
                      <textarea name="competitions" value={formData.competitions} onChange={handleChange} required className="form-control input-default " placeholder="Daha Önce Katıldığınız Yarışma ve Girişimcilik Programları"  />
                      
                      <p className="text-muted fs-12">Katıldığınız yarışmalar, hızlandırma programları veya girişimcilik etkinlikleri nelerdir? Lütfen detaylandırın.</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Aldığınız Ödüller: </label>
                      <textarea name="awards" value={formData.awards} onChange={handleChange}  className="form-control input-default " placeholder="Aldığınız Ödüller"  />
                      <p className="text-muted fs-12">Girişiminizin kazandığı ödüller varsa, bunları belirtiniz.</p>
                      </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Yatırım İhtiyacı Aralığı: </label>
                      <select name="investmentRange" value={formData.investmentRange} onChange={handleChange} required  className="form-control input-default ">
                        <option value="0">Seçiniz</option>
                        <option value="1">0-1.000.000₺</option>
                        <option value="2">1.000.000₺-5.000.000₺</option>
                        <option value="3">5.000.000₺ üzeri</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Net Yatırım İhtiyacı: </label>
                      <input name="investmentNeed" value={formData.investmentNeed} onChange={handleChange} required className="form-control input-default " placeholder="Yatırım İhtiyacı"  />
                      <p className="text-muted fs-12">Şu anda yatırım arayışınız var mı? Eğer varsa, ne kadarlık bir yatırım arıyorsunuz?</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Yatırım Planları: </label>
                      <textarea name="investmentPlans" value={formData.investmentPlans} onChange={handleChange} required className="form-control input-default " placeholder="Yatırım Planları" />
                      <p className="text-muted fs-12">Yatırım aldığınız takdirde aldığınız yatırımı hangi alanlarda değerlendirmeyi planlıyorsunuz?</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Ekstra Bilgiler: </label>
                      <textarea name="extraInfo" value={formData.extraInfo} onChange={handleChange} required className="form-control input-default " placeholder="Ekstra Bilgiler" />
                      <p className="text-muted fs-12">Eklemek istediğiniz başka bilgi veya başarı hikayeniz varsa, lütfen paylaşın.</p>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <button type="submit" className="btn btn-info btn-block">Gönder</button>
                    </div>
                  </div>
                </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EntrepreneurSurvey;