import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © {" "}
          <a href="http://beetinq.com/" target="_blank" rel="noreferrer" className="text-info">
            beetinq
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
