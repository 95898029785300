import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useLocation } from "react-router-dom";


import { toast } from 'react-toastify';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';


import { setUser, loginotp } from '../../features/auth/authSlice'
import { createUser } from '../../features/user/userSlice'
import { auth } from '../../config/firebase';
// image
import logo2 from "../../images/logo-full-white.png";
import login from "../../images/login-bg-1.jpg";
import beetinqlogowhite from "../../images/beetinq/beetinqlogowhite.png";






const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } }; 


    
    const [phone, setPhone] = useState('+90');
    const [hasFilled, setHasFilled] = useState(false);
    const [otp, setOtp] = useState('');
    const [loginstatus, setLoginstatus] = useState('6 haneli doğrulama kodunu giriniz. Yönlendirme otomatik yapılacaktır.');


    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }


        /*
        if (isSuccess || user) {
            navigate('/')
        }
        */

        //dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])



    const sendNumber = async (event) => {
        event.preventDefault();
        if(phone.length>10){
            window.recaptchaVerifier = await new RecaptchaVerifier(auth, 'recaptcha', {
                'size': 'invisible',
                'callback': async (response) => {
                    setHasFilled(true);
                    let appVerifier = window.recaptchaVerifier;
                    await signInWithPhoneNumber(auth, phone, appVerifier)
                        .then((confirmationResult) => {
                            window.confirmationResult = confirmationResult;
                            console.log(window.confirmationResult);
                        }).catch((error) => {
                            console.log(error);
                        });
                },
                'expired-callback': (error) => {
                    console.log("recaptchaVerifier: " + error);
                }
            });
            window.recaptchaVerifier.verify();
        } 
    }

    const verifyOtp = async (event) => {
        let otp = event.target.value;
        setOtp(otp);
        if (otp.length === 6) {
            setLoginstatus("İşlem Devam Ediyor Bekleyiniz");
            // verifu otp
            let confirmationResult = window.confirmationResult;
            await confirmationResult.confirm(otp).then((result) => {
                // User signed in successfully.
                let user = result.user;
                console.log(user);

                localStorage.setItem('user', JSON.stringify(user))

                dispatch(setUser({
                    uid: result.user.uid,
                    phoneNumber: phone,
                }));

                dispatch(loginotp(user));

                if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                    dispatch(createUser(user));
                    navigate('/MyProfile');
                    console.log('Bu kullanıcı ilk defa giriş yaptı.');
                } else {
                    console.log('Bu kullanıcı daha önce giriş yapmıştır.');
                    navigate(from);
                }

                

                toast.success("Giriş başarılı");
            }).catch((error) => {
                toast.warning("Kod doğrulanamadı lütfen tekrar deneyin.");
            });
        }
    }


    if (!hasFilled) {
        return (
            <div className="login-wrapper">
                <div className="login-aside-left" style={{ backgroundImage: "url(" + login + ")" }}>
                    <Link to="/" className="login-logo">
                        <img src={beetinqlogowhite} alt="" width={"60%"} />
                    </Link>
                    <div className="login-description">
                        <h2 className="text-white mb-4">Toplantılarınız<br></br>İş Etkinlikleriniz<br></br>için Ortak Platform</h2>
                        <p className="fs-16">beetinq ile Toplantı ve İş Etkinliği oluşturun, paylaşın ve iş birliği yapın. En büyük iş ağı platformunda yerinizi alın.</p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"https://www.facebook.com/beetinq"}><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to={"https://www.instagram.com/beetinq"}><i className="fa fa-instagram"></i></Link></li>
                            <li><Link to={"https://www.linkedin.com/company/beetinq"}><i className="fa fa-linkedin"></i></Link></li>
                            <li><Link to={"https://beetinq.com"}><i className="fa fa-globe"></i></Link></li>
                        </ul>
                        <div className="mt-5">
                            <Link to={"https://beetinq.com/"} className="text-white">© 2021 beetinq</Link>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                        <div className="col-xl-6 col-xxl-8">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">

                                            <div className=" mb-3">
                                                <h2 className="text-info">Hoşgeldiniz</h2>
                                            </div>
                                            <h4 className=" mb-4 ">Bilgileri girerek oturum açın</h4>
                                            <form onSubmit={sendNumber}>
                                                <div className="form-group mb-3">
                                                    <label className="">
                                                        <strong>Telefon Numarası</strong>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control  mb-3"
                                                        value={phone} onChange={(event) => setPhone(event.target.value)}
                                                    />
                                                    <label className="">
                                                        Telefonunuza 6 haneli kod gönderilecektir.
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-info btn-block"
                                                    >
                                                        İleri
                                                    </button>
                                                </div>
                                            </form>
                                            <div id="recaptcha"></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    } else {
        return (
            <div className="login-wrapper">
                <div className="login-aside-left" style={{ backgroundImage: "url(" + login + ")" }}>
                    <Link to="/" className="login-logo">
                        <img src={beetinqlogowhite} alt="" width={"60%"} />
                    </Link>
                    <div className="login-description">
                        <h2 className="text-white mb-4">Toplantılarınız<br></br>İş Etkinlikleriniz<br></br>için Ortak Platform</h2>
                        <p className="fs-16">beetinq ile Toplantı ve İş Etkinliği oluşturun, paylaşın ve iş birliği yapın. En büyük iş ağı platformunda yerinizi alın.</p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"https://www.facebook.com/beetinq"}><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to={"https://www.instagram.com/beetinq"}><i className="fa fa-instagram"></i></Link></li>
                            <li><Link to={"https://www.linkedin.com/company/beetinq"}><i className="fa fa-linkedin"></i></Link></li>
                            <li><Link to={"https://beetinq.com"}><i className="fa fa-globe"></i></Link></li>
                        </ul>
                        <div className="mt-5">
                            <Link to={"https://beetinq.com/"} className="text-white">© 2021 beetinq</Link>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                        <div className="col-xl-6 col-xxl-8">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">

                                            <div className=" mb-3">
                                                <h2 className="text-info">Tebrikler</h2>
                                            </div>
                                            <h4 className=" mb-4 ">Bir adım kaldı</h4>
                                            <form>
                                                <div className="form-group mb-3">

                                                    <label className="">
                                                        <strong>Doğrulama Kodu</strong>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        value={otp} onChange={verifyOtp}
                                                    />
                                                    <label className="">
                                                        {loginstatus}
                                                    </label>

                                                </div>

                                            </form>
                                            <div id="recaptcha"></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }






};

const mapStateToProps = (state) => {
    console.log('state');
    console.log(state);
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default Login;
