
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userService from './userService'

const initialState = {
    user: [],
    users: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })


            .addCase(updateUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.users.unshift(action.payload)
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getUser.pending, (state) => {
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

    }
})

export const createUser = createAsyncThunk('user/createUser', async (user, thunkAPI) => {
    try {
        return await userService.createUser(user.uid, user.phoneNumber)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getUser = createAsyncThunk('user/getUser', async (thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid;
        console.log("getUser userId: " + userId);
        return await userService.getUser(userId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateUser = createAsyncThunk('user/updateUser', async (userData, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid;
        return await userService.updateUser(userData, userId);
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message);
    }
})

export const { reset } = userSlice.actions
export default userSlice.reducer
