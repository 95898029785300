import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import {  updateField, submitForm  } from '../../features/survey/InvestorSurveySlice';

import { collection, addDoc } from 'firebase/firestore';
import { db } from "../../config/firebase";
import { Button, Dropdown, Modal, Row, Col, Card, Tab, Nav } from "react-bootstrap";



function InvestorSurvey() {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.InvestorSurveyReducer.formData);
  const submitted = useSelector((state) => state.InvestorSurveyReducer.submitted);
  const myUserId = useSelector((state) => state.auth.user.uid);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ name, value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // formData'ya myUserId'yi ekleyelim
    const formDataWithUser = {
      ...formData,
      userId: myUserId,
    };

    try {
      // Firestore'a eklerken formDataWithUser'ı kullanıyoruz
      await addDoc(collection(db, 'surveysinvestor'), formDataWithUser);
      dispatch(submitForm());
      alert('Survey submitted successfully!');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (

    <Fragment>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="App">
                <h1>Siber Güç Türkiye Yatırımcı Anketi</h1>
                {submitted ? (
                  <h2>Anket başarıyla gönderildi!</h2>
                ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="row">

                      <div className="form-group mb-3 col-md-12">
                      <label>Adınız Soyadınız: </label>
                      <input name="userName" value={formData.userName} onChange={handleChange} required className="form-control input-default " placeholder="Adınız Soyadınız" />
                      <p className="text-muted fs-12">Lütfen adınızı soyadınızı adını belirtin</p>
                    </div>
                        
                      <div className="form-group mb-3 col-md-12">
                        <label>Şirket Adı: </label>
                        <input name="companyName" value={formData.companyName} onChange={handleChange} required className="form-control input-default " placeholder="Şirket Adı" />
                        <p className="text-muted fs-12">Lütfen şirketinizin adını belirtin</p>
                      </div>
                          
                      <div className="form-group mb-3 col-md-12">
                        <label>Yatırım yapacağınız girişim dikeyi nedir: </label>
                        <select name="vertical" value={formData.vertical} onChange={handleChange} required  className="form-control input-default ">
                          <option value="0">Seçiniz</option>
                          <option value="1">Siber Güvenlik</option>
                          <option value="2">Yapay Zeka</option>
                          <option value="3">Her İkisi</option>
                        </select>
                      </div>

              
                      <div className="form-group mb-3 col-md-12">
                        <label>Yatırım bütçeniz nedir: </label>
                        <select name="investmentRange" value={formData.investmentRange} onChange={handleChange} required  className="form-control input-default ">
                          <option value="0">Seçiniz</option>
                          <option value="1">0-1.000.000₺</option>
                          <option value="2">1.000.000₺-5.000.000₺</option>
                          <option value="3">5.000.000₺ üzeri</option>
                        </select>
                      </div>
                      
                      <div className="form-group mb-3 col-md-12">
                        <button type="submit" className="btn btn-info btn-block">Gönder</button>
                      </div>
                        
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default InvestorSurvey;