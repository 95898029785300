import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import profileReducer from '../features/profile/profileSlice';
import eventReducer from '../features/event/eventSlice';
import userReducer from '../features/user/userSlice';
import InvestorSurveyReducer from '../features/survey/InvestorSurveySlice';
import EntrepreneurSurveyReducer from '../features/survey/EntrepreneurSurveySlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    //todos: todoReducer,
    event: eventReducer,
    user: userReducer,
    profile: profileReducer,
    EntrepreneurSurveyReducer: EntrepreneurSurveyReducer,
    InvestorSurveyReducer: InvestorSurveyReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: true
  })
});