import React, { Fragment, useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMyProfile, updateMyProfile, reset } from '../../features/profile/profileSlice'



//** Import Image */

import profile08 from "../../images/profile/8.jpg";
import profile09 from "../../images/profile/9.jpg";
const MyProfile = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();


	const isAuthenticated = useSelector((state) => state.auth.user !== null);
	const { profile, isSuccess, isError, message } = useSelector((state) => state.profile);


	const [activeToggle, setActiveToggle] = useState("posts");
	const [sendMessage, setSendMessage] = useState(false);
	const [postModal, setPostModal] = useState(false);
	const [cameraModal, setCameraModal] = useState(false);
	const [linkModal, setLinkModal] = useState(false);
	const [replayModal, setReplayModal] = useState(false);
	const options = {
		settings: {
			overlayColor: "#000000",
		},
	};

	useEffect(() => {
		if (!isAuthenticated) { navigate('/login') }
		if (isError) { console.log(message); }

		dispatch(getMyProfile())
		return () => {
			if (isAuthenticated) { dispatch(reset()) }
		}
	}, [isAuthenticated, navigate, isError, message, dispatch])

	const [profileName, setProfileName] = useState(profile.name)
	const [profileSurName, setProfileSurName] = useState(profile.surName)
	const [profileEmail, setProfileEmail] = useState(profile.userEmail)
	const [profileJob, setProfileJob] = useState(profile.job)

	const profileData = {
		name: profileName,
		surName: profileSurName,
		userEmail: profileEmail,
		job: profileJob,
	};


	const onSubmitProfile = e => {
		e.preventDefault();

		if (profileName !== '' || profileSurName !== '') {
			dispatch(updateMyProfile(profileData));
			console.log(profileData);
			navigate('/dashboard');
		}

	}


	return (
		<Fragment>


			<div className="row">
				<div className="col-lg-12">
					<div className="profile card card-body px-3 pt-3 pb-0">
						<div className="profile-head">
							<div className="photo-content ">
								<div className="cover-photo rounded"></div>
							</div>
							<div className="profile-info">

								<div className="profile-photo">


									{profile.thumbnail == "" ? (
										<img
											src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
											className="img-fluid rounded-circle"
											alt="profile"
										/>
									) : (
										<img
											src={profile.thumbnail}
											className="img-fluid rounded-circle"
											alt="profile"
										/>
									)}
								</div>

								<div className="profile-details">
									<div className="profile-name px-3 pt-2">
										<h4 className="text-info mb-0">{profile.name} {profile.surName}</h4>
										<p>{profile.job}</p>
									</div>
									<div className="profile-email px-2 pt-2">
										<h4 className="text-muted mb-0">{profile.userEmail}</h4>
										<p>{profile.phoneNumber}</p>
									</div>

									{/* 
									<Dropdown className="dropdown ms-auto">
										<Dropdown.Toggle
											variant="primary"
											className="btn btn-primary light sharp i-false"
											data-toggle="dropdown"
											aria-expanded="true"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												//    xmlns:xlink="http://www.w3.org/1999/xlink"
												width="18px"
												height="18px"
												viewBox="0 0 24 24"
												version="1.1"
											>
												<g
													stroke="none"
													strokeWidth="1"
													fill="none"
													fillRule="evenodd"
												>
													<rect x="0" y="0" width="24" height="24"></rect>
													<circle fill="#000000" cx="5" cy="12" r="2"></circle>
													<circle fill="#000000" cx="12" cy="12" r="2"></circle>
													<circle fill="#000000" cx="19" cy="12" r="2"></circle>
												</g>
											</svg>
										</Dropdown.Toggle>
										<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
											<Dropdown.Item className="dropdown-item">
												<i className="fa fa-user-circle text-primary me-2" />
												View profile
											</Dropdown.Item>
											<Dropdown.Item className="dropdown-item">
												<i className="fa fa-users text-primary me-2" />
												Add to close friends
											</Dropdown.Item>
											<Dropdown.Item className="dropdown-item">
												<i className="fa fa-plus text-primary me-2" />
												Add to group
											</Dropdown.Item>
											<Dropdown.Item className="dropdown-item">
												<i className="fa fa-ban text-primary me-2" />
												Block
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
									*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-4">
					<div className="row">
						<div className="col-lg-12">
							<div className="card">
								<div className="card-body">
									<div className="profile-statistics">
										<div className="text-center">
											<div className="row">
												<div className="col">
													<h3 className="m-b-0">{profile.connections?.length}</h3><span>Bağlantı</span>
												</div>
												<div className="col">
													<h3 className="m-b-0">{profile.meetings?.length}</h3> <span>Toplantı</span>
												</div>
											</div>
											{/* 
											<div className="mt-4">
												<Link to="/post-details" className="btn btn-primary mb-1 me-1">Follow</Link>
												<Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => setSendMessage(true)}>Send Message</Button>
											</div>
											*/}
										</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h5 className="text-info">İlgi Alanları</h5>
								</div>
								<div className="card-body pt-3"	>
									<div className="profile-blog ">
										{profile.interests?.map((list, index) => (
											<Link to="/app-profile" className="btn btn-info light btn-xs mb-1 me-1">{list}</Link>
										))}
										<br />
										İlgi alanlarınızı düzenlemek için uygulamayı indiriniz.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-8">
					<div className="card">
						<div className="card-body">


							<div className="settings-form">
								<h4 className="text-info mb-4">Profil Bilgilerini Güncelle</h4>
								<form onSubmit={onSubmitProfile}>

									<div className="row">

										<div className="form-group mb-3 col-md-6">
											<label className="form-label" >Ad</label>
											<input type="text" placeholder="Ad" className="form-control" value={profileName} onChange={(e) => setProfileName(e.target.value)} />
										</div>

										<div className="form-group mb-3 col-md-6">
											<label className="form-label">Soyad</label>
											<input type="text" placeholder="Soyad" className="form-control" value={profileSurName} onChange={(e) => setProfileSurName(e.target.value)} />
										</div>

									</div>

									<div className="form-group mb-3">
										<label className="form-label">Email</label>
										<input type="mail" placeholder="Email" className="form-control" value={profileEmail} onChange={(e) => setProfileEmail(e.target.value)} />
									</div>

									<div className="form-group mb-3">
										<label className="form-label">Firma/Kurum/Görev</label>
										<input type="text" placeholder="Firma/Kurum/Görev" className="form-control" value={profileJob} onChange={(e) => setProfileJob(e.target.value)} />
									</div>

									<button className="btn btn-info" type="submit">Kaydet</button>

								</form>
							</div>


						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default MyProfile;
