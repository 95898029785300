
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import surveyService from './InvestorSurveyService'

const initialState = {
    formData: {
        userId:'',
        companyName: '',
        foundationYear: '',
        solutions: '',
        teamSize: '',
        customerProfile: '',
        turnover: '',
        futurePlans: '',
        exportStatus: '',
        localizationRate: '',
        researchDevelopment: '',
        competitions: '',
        awards: '',
        investmentNeed: '',
        investmentPlans: '',
        extraInfo: '',
        vertical: '', // Girişimin dikeyi için yeni alan
        investmentRange: '' // Yatırım ihtiyacı aralığı için yeni alan
    },
    submitted: false,
  };

const investorSurveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        updateField: (state, action) => {
            const { name, value } = action.payload;
            state.formData[name] = value;
        },
        submitForm: (state) => {
            state.submitted = true;
        },
        resetForm: (state) => {
            state.formData = initialState.formData;
            state.submitted = false;
        },
    },
  });


export const { updateField, submitForm, resetForm } = investorSurveySlice.actions;
export default investorSurveySlice.reducer;
