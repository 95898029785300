import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns';

import Spinner from '../../components/Spinner/Spinner'
import { getEvents,getEventsFront, reset } from '../../features/event/eventSlice'



function Dashboard() {



    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const isAuthenticated = useSelector((state) => state.auth.user !== null);
    const { events, eventsPast, eventsFront, isLoading, isError, message } = useSelector((state) => state.event);
    const myUserId = useSelector((state) => state.auth.user.uid);

    //navigate('/EventDetail/5Bcv9TWGyDJFXgyCYOTa');


    useEffect(() => {

        if (!isAuthenticated) {
            navigate('/login')
        }

        if (isError) {
            console.log(message);
        }


        dispatch(getEvents());
        dispatch(getEventsFront());

        /*
        return () => {
            if (isAuthenticated) {
                dispatch(reset())
            }
        }
        */

    }, [isAuthenticated, navigate, isError, message, dispatch])


    if (isLoading) {
        return <Spinner />
    }



    //ibuW9umAJZjk4VcWiw2S



    return (
        <>



            <div className="row mb-4">

            {eventsFront.length > 0 ? (
                <>
                    <h3 className="mb-4">Öne Çıkan Etkinlikler</h3>

                    {eventsFront.map((list, index) => (
                        <>
                            {new Date(list.startDate.toDate().getTime() - 5 * 60000) <= new Date() && new Date(list.endDate.toDate().getTime() + 5 * 60000) >= new Date() ? (
                                <>
                                    < div className="col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={index} >
                                        <Link to={"/EventDetail/" + list.meetingId}>
                                            <div className="card project-boxed">
                                                <div className="img-bx">
                                                    <img src={list.coverPhoto} alt="" className=" me-3 card-list-img w-100 cover_event_list" width="130" />
                                                </div>
                                                <div className="card-header align-items-start">
                                                    <div>

                                                        <h6 className="fs-18 font-w500 mb-3 text-black user-name titlestyle"> <span class="badge badge-danger fs-12"><i className="fa fa-play"></i></span> {list.title}</h6>
                                                        <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar me-3" aria-hidden="true"></i>Devam Ediyor</div>
                                                        <div className="text-dark fs-14 text-nowrap"><i className="fa fa-users me-3" aria-hidden="true"></i>{list.users?.length} Katılımcı</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <>
                                    < div className="col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={index} >
                                        <Link to={"/EventDetail/" + list.meetingId}>
                                            <div className="card project-boxed">
                                                <div className="img-bx">
                                                    <img src={list.coverPhoto} alt="" className=" me-3 card-list-img w-100 cover_event_list" width="130" />
                                                </div>
                                                <div className="card-header align-items-start">
                                                    <div>

                                                        <h6 className="fs-18 font-w500 mb-3 text-black user-name titlestyle">{list.title}</h6>
                                                        <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar me-3" aria-hidden="true"></i>{format(list.startDate.toDate(), "dd MMM yyyy HH:mm")}</div>
                                                        <div className="text-dark fs-14 text-nowrap"><i className="fa fa-users me-3" aria-hidden="true"></i>{list.users?.length} Katılımcı</div>
                                                    </div>
                                                </div>

                                                {list.users?.indexOf(myUserId) < 0 ? (
                                                    <a href={"/EventDetail/" + list.meetingId} class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        <svg class="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.648712 9.33093V14.3327C0.648712 15.2524 1.39698 16 2.31597 16H14.3203C15.24 16 15.9875 15.2524 15.9875 14.3327V9.33093H0.648712Z" fill="white"></path>
                                                            <path d="M7.89532 1.52481L5.39974 2.11702L7.49049 5.19347L10.1935 4.5786L7.89532 1.52481Z" fill="white"></path>
                                                            <path d="M4.70618 2.28177L2.01652 2.91931L4.08526 5.9671L6.79224 5.35153L4.70618 2.28177Z" fill="white"></path>
                                                            <path d="M15.9769 2.90997L15.4093 0.757192C15.2786 0.23366 14.7424 -0.0957707 14.2136 0.0249289L11.9728 0.55712L14.0989 3.69159L15.7281 3.3208C15.8168 3.30079 15.8928 3.24543 15.9395 3.16809C15.9862 3.09075 15.9996 2.998 15.9769 2.90997Z" fill="white"></path>
                                                            <path d="M11.2785 0.721863L8.60357 1.35675L10.9057 4.41719L13.4006 3.84964L11.2785 0.721863Z" fill="white"></path>
                                                            <path d="M4.00924 5.99707L2.9422 8.66404H5.55849L6.62553 5.99707H4.00924Z" fill="white"></path>
                                                            <path d="M7.34378 5.99707L6.27674 8.66404H8.89299L9.96006 5.99707H7.34378Z" fill="white"></path>
                                                            <path d="M15.6541 5.99707H14.0129L12.9458 8.66469H15.9876V6.3305C15.9875 6.14578 15.8388 5.99707 15.6541 5.99707Z" fill="white"></path>
                                                            <path d="M10.6783 5.99707L9.61125 8.66404H12.2269L13.2946 5.99707H10.6783Z" fill="white"></path>
                                                            <path d="M1.32296 3.08472L0.779418 3.21342C0.514667 3.27344 0.290587 3.43416 0.148536 3.66493C0.00648451 3.89635 -0.0355306 4.16845 0.0304931 4.43186L0.648715 6.8754V8.66404H2.22394L3.22495 6.16248L3.38767 6.12581L1.32296 3.08472Z" fill="white"></path>
                                                        </svg>

                                                        ETKİNLİĞE KAYIT OL

                                                    </a>
                                                ) : (
                                                    <a href={"/EventDetail/" + list.meetingId} class="btn  btn-info text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        <svg class="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.648712 9.33093V14.3327C0.648712 15.2524 1.39698 16 2.31597 16H14.3203C15.24 16 15.9875 15.2524 15.9875 14.3327V9.33093H0.648712Z" fill="white"></path>
                                                            <path d="M7.89532 1.52481L5.39974 2.11702L7.49049 5.19347L10.1935 4.5786L7.89532 1.52481Z" fill="white"></path>
                                                            <path d="M4.70618 2.28177L2.01652 2.91931L4.08526 5.9671L6.79224 5.35153L4.70618 2.28177Z" fill="white"></path>
                                                            <path d="M15.9769 2.90997L15.4093 0.757192C15.2786 0.23366 14.7424 -0.0957707 14.2136 0.0249289L11.9728 0.55712L14.0989 3.69159L15.7281 3.3208C15.8168 3.30079 15.8928 3.24543 15.9395 3.16809C15.9862 3.09075 15.9996 2.998 15.9769 2.90997Z" fill="white"></path>
                                                            <path d="M11.2785 0.721863L8.60357 1.35675L10.9057 4.41719L13.4006 3.84964L11.2785 0.721863Z" fill="white"></path>
                                                            <path d="M4.00924 5.99707L2.9422 8.66404H5.55849L6.62553 5.99707H4.00924Z" fill="white"></path>
                                                            <path d="M7.34378 5.99707L6.27674 8.66404H8.89299L9.96006 5.99707H7.34378Z" fill="white"></path>
                                                            <path d="M15.6541 5.99707H14.0129L12.9458 8.66469H15.9876V6.3305C15.9875 6.14578 15.8388 5.99707 15.6541 5.99707Z" fill="white"></path>
                                                            <path d="M10.6783 5.99707L9.61125 8.66404H12.2269L13.2946 5.99707H10.6783Z" fill="white"></path>
                                                            <path d="M1.32296 3.08472L0.779418 3.21342C0.514667 3.27344 0.290587 3.43416 0.148536 3.66493C0.00648451 3.89635 -0.0355306 4.16845 0.0304931 4.43186L0.648715 6.8754V8.66404H2.22394L3.22495 6.16248L3.38767 6.12581L1.32296 3.08472Z" fill="white"></path>
                                                        </svg>

                                                        KAYIT OLDUNUZ

                                                    </a>
                                                )}

                                            </div>
                                        </Link>
                                    </div>
                                </>
                            )}

                        </>
                    ))}
                </>
            ) : (
                <>
                    <p>Öne Çıkan etkinlik bulunamadı</p>
                </>
            )}
            </div >






            <div className="row mb-4">

                {events.length > 0 ? (
                    <>
                        <h3 className="mb-4">Tüm Etkinlikler</h3>

                        {events.map((list, index) => (
                            <>
                                {new Date(list.startDate.toDate().getTime() - 5 * 60000) <= new Date() && new Date(list.endDate.toDate().getTime() + 5 * 60000) >= new Date() ? (
                                    <>
                                        < div className="col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={index} >
                                            <Link to={"/EventDetail/" + list.meetingId}>
                                                <div className="card project-boxed">
                                                    <div className="img-bx">
                                                        <img src={list.coverPhoto} alt="" className=" me-3 card-list-img w-100 cover_event_list" width="130" />
                                                    </div>
                                                    <div className="card-header align-items-start">
                                                        <div>

                                                            <h6 className="fs-18 font-w500 mb-3 text-black user-name titlestyle"> <span class="badge badge-danger fs-12"><i className="fa fa-play"></i></span> {list.title}</h6>
                                                            <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar me-3" aria-hidden="true"></i>Devam Ediyor</div>
                                                            <div className="text-dark fs-14 text-nowrap"><i className="fa fa-users me-3" aria-hidden="true"></i>{list.users?.length} Katılımcı</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        < div className="col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={index} >
                                            <Link to={"/EventDetail/" + list.meetingId}>
                                                <div className="card project-boxed">
                                                    <div className="img-bx">
                                                        <img src={list.coverPhoto} alt="" className=" me-3 card-list-img w-100 cover_event_list" width="130" />
                                                    </div>
                                                    <div className="card-header align-items-start" >
                                                        <div>

                                                            <h6 className="fs-18 font-w500 mb-3 text-black user-name titlestyle">{list.title}</h6>
                                                            <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar me-3" aria-hidden="true"></i>{format(list.startDate.toDate(), "dd MMM yyyy HH:mm")}</div>
                                                            <div className="text-dark fs-14 text-nowrap"><i className="fa fa-users me-3" aria-hidden="true"></i>{list.users?.length} Katılımcı</div>
                                                        </div>
                                                    </div>

                                                    {list.users?.indexOf(myUserId) < 0 ? (
                                                        <a href={"/EventDetail/" + list.meetingId} class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            <svg class="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.648712 9.33093V14.3327C0.648712 15.2524 1.39698 16 2.31597 16H14.3203C15.24 16 15.9875 15.2524 15.9875 14.3327V9.33093H0.648712Z" fill="white"></path>
                                                                <path d="M7.89532 1.52481L5.39974 2.11702L7.49049 5.19347L10.1935 4.5786L7.89532 1.52481Z" fill="white"></path>
                                                                <path d="M4.70618 2.28177L2.01652 2.91931L4.08526 5.9671L6.79224 5.35153L4.70618 2.28177Z" fill="white"></path>
                                                                <path d="M15.9769 2.90997L15.4093 0.757192C15.2786 0.23366 14.7424 -0.0957707 14.2136 0.0249289L11.9728 0.55712L14.0989 3.69159L15.7281 3.3208C15.8168 3.30079 15.8928 3.24543 15.9395 3.16809C15.9862 3.09075 15.9996 2.998 15.9769 2.90997Z" fill="white"></path>
                                                                <path d="M11.2785 0.721863L8.60357 1.35675L10.9057 4.41719L13.4006 3.84964L11.2785 0.721863Z" fill="white"></path>
                                                                <path d="M4.00924 5.99707L2.9422 8.66404H5.55849L6.62553 5.99707H4.00924Z" fill="white"></path>
                                                                <path d="M7.34378 5.99707L6.27674 8.66404H8.89299L9.96006 5.99707H7.34378Z" fill="white"></path>
                                                                <path d="M15.6541 5.99707H14.0129L12.9458 8.66469H15.9876V6.3305C15.9875 6.14578 15.8388 5.99707 15.6541 5.99707Z" fill="white"></path>
                                                                <path d="M10.6783 5.99707L9.61125 8.66404H12.2269L13.2946 5.99707H10.6783Z" fill="white"></path>
                                                                <path d="M1.32296 3.08472L0.779418 3.21342C0.514667 3.27344 0.290587 3.43416 0.148536 3.66493C0.00648451 3.89635 -0.0355306 4.16845 0.0304931 4.43186L0.648715 6.8754V8.66404H2.22394L3.22495 6.16248L3.38767 6.12581L1.32296 3.08472Z" fill="white"></path>
                                                            </svg>

                                                            ETKİNLİĞE KAYIT OL

                                                        </a>
                                                    ) : (
                                                        <a href={"/EventDetail/" + list.meetingId} class="btn  btn-info text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            <svg class="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.648712 9.33093V14.3327C0.648712 15.2524 1.39698 16 2.31597 16H14.3203C15.24 16 15.9875 15.2524 15.9875 14.3327V9.33093H0.648712Z" fill="white"></path>
                                                                <path d="M7.89532 1.52481L5.39974 2.11702L7.49049 5.19347L10.1935 4.5786L7.89532 1.52481Z" fill="white"></path>
                                                                <path d="M4.70618 2.28177L2.01652 2.91931L4.08526 5.9671L6.79224 5.35153L4.70618 2.28177Z" fill="white"></path>
                                                                <path d="M15.9769 2.90997L15.4093 0.757192C15.2786 0.23366 14.7424 -0.0957707 14.2136 0.0249289L11.9728 0.55712L14.0989 3.69159L15.7281 3.3208C15.8168 3.30079 15.8928 3.24543 15.9395 3.16809C15.9862 3.09075 15.9996 2.998 15.9769 2.90997Z" fill="white"></path>
                                                                <path d="M11.2785 0.721863L8.60357 1.35675L10.9057 4.41719L13.4006 3.84964L11.2785 0.721863Z" fill="white"></path>
                                                                <path d="M4.00924 5.99707L2.9422 8.66404H5.55849L6.62553 5.99707H4.00924Z" fill="white"></path>
                                                                <path d="M7.34378 5.99707L6.27674 8.66404H8.89299L9.96006 5.99707H7.34378Z" fill="white"></path>
                                                                <path d="M15.6541 5.99707H14.0129L12.9458 8.66469H15.9876V6.3305C15.9875 6.14578 15.8388 5.99707 15.6541 5.99707Z" fill="white"></path>
                                                                <path d="M10.6783 5.99707L9.61125 8.66404H12.2269L13.2946 5.99707H10.6783Z" fill="white"></path>
                                                                <path d="M1.32296 3.08472L0.779418 3.21342C0.514667 3.27344 0.290587 3.43416 0.148536 3.66493C0.00648451 3.89635 -0.0355306 4.16845 0.0304931 4.43186L0.648715 6.8754V8.66404H2.22394L3.22495 6.16248L3.38767 6.12581L1.32296 3.08472Z" fill="white"></path>
                                                            </svg>

                                                            KAYIT OLDUNUZ

                                                        </a>
                                                    )}

                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )}

                            </>
                        ))}
                    </>
                ) : (
                    <>
                        <p>Etkinlik bulunamadı</p>
                    </>
                )}
            </div >

        </>

    )
}

export default Dashboard